import * as React from "react"
import {
  BanknotesIcon,
  BuildingStorefrontIcon,
  CalculatorIcon,
  MagnifyingGlassIcon,
  TruckIcon,
} from "@heroicons/react/24/outline"

const HowItWorks = ({ className, heading = "How it works", boxClassName, arrowClassName }) => {
  const Step = ({ stepImage, stepTitle, stepDescription }) => {
    return (
      <div className="relative w-full grid grid-cols-[1fr_auto] hover:scale-[101%] hover:text-red-700 hover:-translate-y-1 duration-200 ease-in-out gap-4">
        {stepImage}
        <p className="w-full font-bold text-lg">
          {stepTitle}{" "}
          <span className="mx-auto lg:mx-0 max-w-[450px] row-span-1 @[56rem]:col-span-2 font-normal">
            {stepDescription}
          </span>
        </p>
      </div>
    )
  }
  return (
    <section className={"@container " + className || "w-full p-10 visibility-auto  "}>
      {heading && <h2 className={"pt-10 pb-5 text-center text-4xl uppercase "}>{heading}</h2>}
      <div
        className={
          (heading ? "pb-10 " : "py-10 ") +
          " grid grid-cols-1 lg:grid-cols-2 gap-20 w-11/12 max-w-[1366px] mx-auto"
        }
      >
        <div className="space-y-4">
          <h3 className="text-center text-3xl font-black uppercase w-full">ONLINE PROCESS</h3>
          <Step
            stepImage={<CalculatorIcon className="min-w-16 w-1/3 mx-auto" />}
            stepTitle={"1. Request a Quote"}
            stepDescription={
              "Find out how much is your watch or jewelry worth? The more information & photos you share with us the more accurate the market valuation will be. Upon completion of an online submission our experts will contact you with an initial quote."
            }
          />

          <Step
            stepImage={<TruckIcon className="min-w-16 w-1/3 mx-auto" />}
            stepTitle={"2. Send Items for Inspection"}
            stepDescription={
              <>
                We will provide Easy Ship box and packing materials with our address label. Simply
                take it to the post office. Send it via <b>registered mail and fully insured*</b>,
                and we will refund your cost of shipping and insurance upon purchase.
                <br />
                <em className="block my-4">
                  * Be sure to protect your valuables with adequate shipping insurance
                </em>
              </>
            }
          />

          <Step
            stepImage={<BanknotesIcon className="min-w-16 w-1/3 mx-auto" />}
            stepTitle={"3. Receive Payment"}
            stepDescription={
              "Once the inspection is complete and details of the transaction are confirmed, you will quickly receive your payment via check or bank wire."
            }
          />
        </div>
        <div className="space-y-4">
          <h3 className="text-center text-3xl font-black uppercase w-full">IN-STORE PROCESS</h3>
          <Step
            stepImage={<BuildingStorefrontIcon className="min-w-16 w-1/3 mx-auto" />}
            stepTitle={"1. Walk-In our Showroom"}
            stepDescription={
              "No appointment necessary. Visit our store at 9595 Harding Avenue, Surfside, FL 33154. Across the street form world-famous Bal Harbour Shops."
            }
          />

          <Step
            stepImage={<MagnifyingGlassIcon className="min-w-16 w-1/3 mx-auto" />}
            stepTitle={"2. In-House Inspection"}
            stepDescription={
              "Jewelry and Watch buying experts along with in-house master trained watchmakers on-site will perform an in-house inspection for an immediate evaluation."
            }
          />

          <Step
            stepImage={<BanknotesIcon className="min-w-16 w-1/3 mx-auto" />}
            stepTitle={"3. Receive Payment"}
            stepDescription={
              "Once the inspection is complete and details of the transaction are confirmed, you will quickly receive your payment via check or bank wire."
            }
          />
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
