/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import { useState } from "react"
import Icons from "./icons/Icons"
import Header from "./layout/Header"
import Footer from "./layout/Footer"

import "../styles/main.scss"

const Layout = ({ children, breadcrumbs, className, shop, title, canonical }) => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-W4V7LW"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      <Icons />
      <Header
        breadcrumbs={breadcrumbs}
        canonical={canonical}
        shop={shop}
        title={title}
        mobileMenu={mobileMenuOpened}
        setMobileMenu={setMobileMenuOpened}
      />
      <main className={mobileMenuOpened ? " " : className || " mx-auto"}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  ]),
  className: PropTypes.string,
  canonical: PropTypes.string,
  title: PropTypes.string,
  shop: PropTypes.bool,
}

Layout.defaultProps = {
  children: null,
  className: null,
  breadcrumbs: false,
  canonical: null,
  shop: false,
  title: null,
}

export default Layout
