import * as React from "react"
import { useRef, useLayoutEffect } from "react"
import { useEffect, useState } from "react"
import { IconChevronLeft, IconChevronRight } from "./icons/Icons"
import TailwindScreens, { TailwindScreensKeys } from "../helpers/tailwind"


const Carousel = ({
  children,
  infiniteLoop,
  className,
  scrollStep,
  slides,
  startItem,
  onSlidesChange,
  onCurrentChange,
  gap,
  slidesQuantitty,
  scrollbar,
  layoutClassName,
}) => {
  const getStep = () => {
    return (scrollStep || 1) > show ? show : scrollStep || 1
  }
  const [show, setShow] = useState(slides["xs"] || 1)
  const [currentIndex, setCurrentIndex] = useState((infiniteLoop ? show : 0) + (startItem || 0))
  const [length, setLength] = useState(children.length)
  const [isRepeating, setIsRepeating] = useState(infiniteLoop && children.length > show)
  const [transitionEnabled, setTransitionEnabled] = useState(true)
  const [touchPosition, setTouchPosition] = useState(null)
  const ref = useRef(null)
  const scroller = useRef(null)
  const [transformChunk, setTransformChunk] = useState(0)

  const [transform, setTransform] = useState(0)
  useLayoutEffect(() => {
    setTransformChunk(ref?.current?.offsetWidth)
  }, [])
  // const carouselContent =
  const transformItems = {
    0: 0,
    1: transformChunk / slidesQuantitty,
    2: (transformChunk / slidesQuantitty) * 2,
    3: (transformChunk / slidesQuantitty) * 3,
    4: (transformChunk / slidesQuantitty) * 4,
    5: (transformChunk / slidesQuantitty) * 5,
  }

  useEffect(() => {
    window.mm = window.mm || {}
    const isMatch = size => {
      return (window.mm[size] || window.matchMedia(TailwindScreens[size])).matches
    }
    function handleScreenUpdate() {
      let screenSize = "xs"
      TailwindScreensKeys.forEach(size => {
        if (slides[size] && isMatch(size)) {
          screenSize = size
        }
      })
      const val = slides[screenSize] || 1
      if (onSlidesChange) {
        onSlidesChange(val)
      }
      setShow(val)
    }

    TailwindScreensKeys.forEach(size => {
      window.mm[size] = window.matchMedia(TailwindScreens[size])
      if (slides[size]) {
        window.mm[size].addEventListener("change", handleScreenUpdate, { passive: true })
      }
    })
    handleScreenUpdate()
    // clean up
    return () => {
      TailwindScreensKeys.forEach(size => {
        window.mm[size].removeEventListener("change", handleScreenUpdate)
      })
    }
  }, [slides, onSlidesChange, length])

  // Set the length to match current children from props
  useEffect(() => {
    setLength(children.length)
    setIsRepeating(infiniteLoop && children.length > show)
  }, [children, infiniteLoop, show])

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true)
      }
    }
  }, [currentIndex, isRepeating, show, length])

  const next = () => {
    if (isRepeating || currentIndex < length - show) {
      let step = Math.max(1, Math.min(getStep(), length - show - currentIndex))

      setCurrentIndex(prevState => {
        if (onCurrentChange) {
          onCurrentChange(prevState + step - (infiniteLoop ? show : 0))
        }
        return prevState + step
      })
      setTransform(transformItems[currentIndex + 1])
    }
  }

  const prev = () => {
    if (isRepeating || currentIndex > 0) {
      let step = Math.max(1, Math.min(getStep(), currentIndex))

      setCurrentIndex(prevState => {
        if (onCurrentChange) {
          onCurrentChange(prevState - step - (infiniteLoop ? show : 0))
        }
        return prevState - step
      })
      setTransform(transformItems[currentIndex - 1])
    }
  }

  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = e => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      next()
    }

    if (diff < -5) {
      prev()
    }

    setTouchPosition(null)
  }

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false)
        setCurrentIndex(length)
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false)
        setCurrentIndex(show)
      }
    }
  }

  const renderExtraPrev = () => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(children[length - 1 - index])
    }
    output.reverse()
    return output
  }

  const renderExtraNext = () => {
    let output = []
    for (let index = 0; index < show; index++) {
      output.push(children[index])
    }
    return output
  }
  const widthScroller = 100 / slidesQuantitty
  const styles = {
    transform: `translate(${transform}px)`,
    width: `${widthScroller}%`,
  }
  return (
    <div className="w-full flex flex-col">
      <div className="relative flex w-full">
        {(isRepeating || currentIndex > 0) && (
          <button
            onClick={prev}
            className="absolute z-40 top-1/2 bg-white rounded-full bg-opacity-70 border-2 border-gray-400 -translate-y-1/2 w-10 h-10 left-4 hover:-translate-x-2 duration-300 hidden md:block"
          >
            <IconChevronLeft className={"text-gray-400 text-xl translate-y-[.2rem]"} />
          </button>
        )}
        <div
          className={"overflow-hidden sm:w-8/12 lg:w-10/12 mx-auto gap-4 h-full " + layoutClassName}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className={`carousel show-${show}-gap-${gap} item-${currentIndex} ${
              transitionEnabled ? "" : "no-transition"
            } ${className}`}
            onTransitionEnd={() => handleTransitionEnd()}
          >
            {length > show && isRepeating && renderExtraPrev()}
            {children}
            {length > show && isRepeating && renderExtraNext()}
          </div>
        </div>
        {(isRepeating || currentIndex < length - show) && (
          <button
            onClick={next}
            className="absolute z-10 top-1/2 bg-white rounded-full bg-opacity-70 border-2 border-gray-400 -translate-y-1/2 w-10 h-10 hover:translate-x-2 duration-300 right-5 hidden md:block"
          >
            <IconChevronRight className={"text-gray-400 text-xl translate-y-[.2rem]"} />
          </button>
        )}
      </div>
      {scrollbar && (
        <div className="">
          <div
            className="w-full -translate-y-4 border-t border-t-gray-300 border-b h-[5px] rounded-full bg-gray-200"
            ref={ref}
          >
            <div
              ref={scroller}
              style={styles}
              className={
                "relative bg-gray-400 h-[5px] rounded-full border-b border-b-gray-200 transition-all ease-in-out duration-[0.4s]"
              }
            ></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Carousel
