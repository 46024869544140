import * as React from "react"
import useInViewPort from "../../hooks/useInViewPort"

const Map = ({ className }) => {
  const [nodeRef, isVisible] = useInViewPort()
  return (
    <div ref={nodeRef} className={className || "w-full xl:h-120 visibility-auto"}>
      {isVisible ? (
        <iframe
          title={"Gray & Sons Location on Google Maps"}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3589.4688694509287!2d-80.12569428395246!3d25.88695278358005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20%26%20Sons%20Jewelers!5e0!3m2!1sen!2sus!4v1625950284253!5m2!1sen!2sus"
          width="100%"
          height="100%"
          className={"border-0"}
          allowFullScreen="true"
          loading="lazy"
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default Map
