import * as React from "react"
import { memo } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { uniformUrl } from "../../helpers/StringHelpers"

const ensureStartsWith = (str, char) => {
  if (!str.startsWith(char)) {
    return char + str
  }
  return str
}

const Breadcrumbs = memo(({ breadcrumbs, title, location, origin, canonical }) => {
  const clean = Array.isArray(breadcrumbs)
    ? breadcrumbs.map(m => {
        return { url: m.url, name: m.name }
      })
    : []
  if (title) {
    if (canonical) {
      clean.push({ url: canonical, name: title })
    } else if (location) {
      clean.push({ url: location.replace(origin, ""), name: title })
    }
  }
  clean.forEach(m => {
    m.url = ensureStartsWith(m.url, "/")
  })
  const itemList = [
    {
      "@type": "ListItem",
      position: 1,
      item: { "@id": `${origin}/`, name: "Sell Us Your Jewelry" },
    },
  ]
  clean
    .map(({ name, url }, index) => {
      return {
        "@type": "ListItem",
        position: index + 2,
        item: {
          "@id": `${uniformUrl(url, origin)}`,
          name: name,
        },
      }
    })
    .forEach(m => itemList.push(m))
  const _breadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    name: "Breadcrumbs",
    itemListOrder: "https://schema.org/ItemListOrderAscending",
    numberOfItems: clean.length + 1,
    itemListElement: itemList,
  }
  return (
    <nav
      className={
        "text-md w-11/12 max-w-screen overflow-hidden px-5 xl:px-0 max-w-[1366px] mx-auto flex py-4 text-gray-600 gap-x-2 "
      }
    >
      <Link to={"/"} className={"text-md text-gray-600 hover:text-accent"}>
        Home
      </Link>
      {clean.map(({ url, name }) => {
        return (
          <>
            <span>&gt;</span>
            <Link
              to={url}
              className={
                "text-md text-gray-600 whitespace-nowrap md:whitespace-normal hover:text-accent"
              }
            >
              {name === "New Arrivals" ? "New Purchases" : name}
            </Link>
          </>
        )
      })}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(_breadcrumbs) }}
      />
    </nav>
  )
})

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  ]),
  canonical: PropTypes.string,
  location: PropTypes.string,
  title: PropTypes.string,
}

Breadcrumbs.defaultProps = {
  breadcrumbs: false,
  canonical: null,
  location: null,
  title: null,
}

export default Breadcrumbs
