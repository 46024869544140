import * as React from "react"
import { Link } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { IconChat, IconEmail, IconRing, IconSearch } from "../icons/Icons"
import IconCart from "../../icons/IconCart"
import CategoryImage from "../products/CategoryImage"
import { removeProduct } from "../../state/cart"
import { USD } from "../../helpers/NumberHelper"

const ShopHeader = ({ setKeyword, keyword, onSearch, dropdown, setDropdown }) => {
  const cartLength = useSelector(state => state.cart.products.length)
  const cartTotal = useSelector(state => state.cart.total)
  const cartProducts = useSelector(state => state.cart.products)
  const dispatch = useDispatch()
  return (
    <div className="z-50 bg-accent h-12 w-full flex justify-center items-center text-sm hidden">
      <div className="w-11/12 mx-auto hidden gap-6 text-white md:flex max-w-[1366px]">
        <div className={"hidden flex-none xl:block"}>Contact us 24/7</div>
        <a className={"flex-none uppercase text-white"} href="mailto:sell@grayandsons.com">
          <IconEmail className={"text-xl"} /> Email
        </a>
        <a className={"flex-none uppercase text-white"} href="tel:+13057706955">
          <IconRing className={"text-2xl"} /> Call: 305.770.6955
        </a>
        <div className={"flex-none uppercase text-white"}>
          <IconChat className={"text-lg"} /> Chat
        </div>
        <div className={"flex-grow"}>&nbsp;</div>
        <div className={"flex-none justify-self-end border-b border-white"}>
          <form action={"/search"} method={"get"} onSubmit={onSearch}>
            <label htmlFor={"header-search"} className={"sr-only"}>
              Search
            </label>
            <input
              type={"text"}
              placeholder={"Search"}
              value={keyword}
              id={"header-search"}
              name={"q"}
              onChange={e => {
                setKeyword(e.target.value)
              }}
              className={"bg-accent placeholder-white"}
            />
            <button aria-label={"Search"}>
              <IconSearch />
              <p className={"sr-only"}>Search</p>
            </button>
          </form>
        </div>
        {/*<Link to={'#'}>*/}
        {/*  <IconPerson />*/}
        {/*</Link>*/}
        {/*<Link to={'#'}>*/}
        {/*  <IconFav />*/}
        {/*</Link>*/}

        {/* <div className={"group relative z-30"} tabIndex={0} role={"button"}>
          <div className={"absolute -right-10 h-60 w-16 bg-transparent group-hover:block"} />
          <Link to={"/shopping-bag/"} className={"relative z-10"}>
            <IconCart />
          </Link>
          {cartLength <= 0 ? (
            ""
          ) : (
            <>
              <span className="absolute top-0 right-0 inline-flex translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-red-600 px-2 py-1 text-xs font-bold leading-none text-red-100">
                {cartLength}
              </span>
              <div
                className={
                  "max-w-screen absolute -right-10 mt-2 hidden w-96 animate-fade-in bg-white shadow group-hover:block group-focus:block"
                }
              >
                <div className={"bg-box text-sm text-black"}>
                  <p className={"p-4"}>{cartLength} item(s) in your cart</p>
                  <div className={"bg-white p-4"}>
                    {cartProducts.map(p => {
                      return (
                        <div key={p.sku} className={"flex gap-4"}>
                          <div className={"flex-1"}>
                            <CategoryImage
                              sku={p.sku}
                              alt={p.name}
                              title={p.title}
                              url={"/" + p.url}
                              normal={p.image}
                              thumb={p.image}
                            />
                          </div>
                          <div className={"flex-1"}>
                            {p.brand && p.model ? (
                              <>
                                <p className={"text-bold"}>{p.brand}</p>
                                <p className={"mb-8"}>
                                  {p.model} {p.size}
                                </p>
                              </>
                            ) : (
                              <p className={"text-bold mb-8"}>{p.name}</p>
                            )}
                            {p.qty ? <p>Qty: {p.qty}</p> : ""}
                            {p.color ? <p>Color: {p.color}</p> : ""}
                            {p.size ? <p>Size: {p.size}</p> : ""}
                            <button
                              className={"py-2 text-cta"}
                              aria-label={"Remove " + p.name + " from cart"}
                              onClick={() =>
                                dispatch(
                                  removeProduct({
                                    sku: p.sku,
                                    qty: p.qty,
                                  })
                                )
                              }
                            >
                              Remove
                            </button>
                          </div>
                          <div className={"w-16 flex-none text-right font-bold"}>
                            {USD(p.price)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className={"flex p-4 pb-0 text-right font-bold"}>
                    <p className={"flex-1"}>Subtotal:</p>
                    <p className={"w-16"}>{USD(cartTotal)}</p>
                  </div>
                  <div className={"p-4"}>
                    <Link
                      to={"/shopping-bag/"}
                      className={"block w-full bg-cta p-4 text-center text-white"}
                    >
                      Checkout
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div> */}
      </div>
    </div>
  )
}

export default ShopHeader
