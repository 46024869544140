import * as React from "react"
import { memo } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { slugify } from "../../helpers/StringHelpers"
import IconLoaders from "../../icons/IconLoaders"
import ImageSizes from "../../helpers/ImageSizes"

const getGatsbyImage = (sku, title, alt, normal, productType, purpose) => {
  if (!(sku && title && normal)) return null
  let width
  let height
  if (!purpose) {
    width = 292
    height = 292
  }

  let sizes = "(min-width: 768px) 33.3333vw, (min-width: 1024px) 25vw, 50vw"
  switch (purpose) {
    case "hero":
      width = 640
      height = 640
      sizes = "(min-width: 768px) 50vw, 100vw"
      break
    case "thumb":
      width = 360
      height = 360
      sizes = "(min-width: 768px) 50vw, 360w"
      break
  }
  let srcsetWebp = ""
  let srcsetAvif = ""
  let srcset = ""
  for (const size of ImageSizes) {
    srcset +=
      normal.url
        .replace(/\/o\//, "/" + slugify("used " + title) + "-")
        .replace(/[.]jpg+/, `-w${size}.jpg`) + ` ${size}w,\n`
    srcsetWebp +=
      normal.url
        .replace(/\/o\//, "/" + slugify("pre-owned " + title) + "-")
        .replace(/[.]jpg+/, `-w${size}.webp`) + ` ${size}w,\n`
    srcsetAvif +=
      normal.url
        .replace(/\/o\//, "/" + slugify("vintage " + title) + "-")
        .replace(/[.]jpg+/, `-w${size}.avif`) + ` ${size}w,\n`
  }
  const fallbackUrl = normal.url
    .replace(/\/o\//, "/" + slugify(title) + "-")
    .replace(/[.]jpg+/, "-w360.jpg")
  return {
    gatsbyImageData: {
      layout: "constrained",
      backgroundColor: "#FFFFFF",
      placeholder: {
        children: <IconLoaders type={productType} />,
        className: "m-1",
      },
      images: {
        fallback: {
          src: fallbackUrl,
          srcSet: srcset,
          sizes: sizes,
        },
        sources: [
          {
            srcSet: srcsetAvif,
            type: "image/avif",
            sizes: sizes,
          },
          {
            srcSet: srcsetWebp,
            type: "image/webp",
            sizes: sizes,
          },
        ],
      },
      width: width,
      height: height,
    },
  }
}

const ProductImageComponent = ({ sku, title, image, alt, productType, variant, size }) => {
  let gatsbyImage = getGatsbyImage(
    sku,
    title,
    alt,
    image,
    productType,
    size === 360 ? "thumb" : "hero"
  )
  return (
    <>
      {gatsbyImage ? (
        <GatsbyImage
          id={"product-collection-image-" + sku + variant}
          alt={alt}
          image={getImage(gatsbyImage)}
          objectFit="contain"
        />
      ) : (
        <div className={"placeholder"} />
      )}
    </>
  )
}
const ProductImage = memo(ProductImageComponent)

const CategoryImageComponent = ({
  sku,
  title,
  alt,
  normal,
  url,
  purpose,
  productType,
  className,
}) => {
  let gatsbyImage = getGatsbyImage(sku, title, alt, normal, productType, purpose)
  return (
    <Link to={url}>
      {gatsbyImage ? (
        <GatsbyImage
          id={"product-collection-image-" + sku}
          alt={alt}
          image={getImage(gatsbyImage)}
          className={className + " w-full h-full "}
          objectFit="contain"
        />
      ) : (
        <div className={"placeholder"} />
      )}
    </Link>
  )
}
const CategoryImage = memo(CategoryImageComponent)

export default CategoryImage
export { ProductImage, slugify }
