import * as React from "react"
import { useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import ShopHeader from "./ShopHeader"
import { useLocation } from "@reach/router"
import Breadcrumbs from "./Breadcrumbs"
// import ShopMenu from "./ShopMenu"
import HomeMenuDL from "./HomeMenuDL"
// We may need this in the future, so we will leave it here for now.
// import NewsTicker from "./NewsTicker"

const Header = ({ breadcrumbs, shop, title, canonical, mobileMenu, setMobileMenu }) => {
  const [keyword, setKeyword] = useState("")

  const onSearch = e => {
    e.preventDefault()
    navigate("/search?q=" + keyword, { state: { keyword } })
  }
  return (
    <header itemScope itemType="https://schema.org/WPHeader" className="pt-14">
      {/* <NewsTicker /> */}
      {/* {shop ? (
        <ShopHeader
          keyword={keyword}
          setKeyword={setKeyword}
          onSearch={onSearch}
          dropdown={mobileMenu}
          setDropdown={setMobileMenu}
        />
      ) : (
        ""
      )}
      {shop ? (
        <ShopMenu dropdown={mobileMenu} setDropdown={setMobileMenu} />
      ) : (
        <HomeMenu
          keyword={keyword}
          setKeyword={setKeyword}
          onSearch={onSearch}
          dropdown={mobileMenu}
          setDropdown={setMobileMenu}
          shop={shop}
        />
      )} */}
      <HomeMenuDL
      // keyword={keyword}
      // setKeyword={setKeyword}
      // onSearch={onSearch}
      // dropdown={mobileMenu}
      // setDropdown={setMobileMenu}
      // shop={shop}
      />
      {breadcrumbs ? (
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          canonical={canonical}
          title={title}
          location={useLocation().href}
          origin={useLocation().origin}
        />
      ) : (
        ""
      )}
    </header>
  )
}

Header.propTypes = {
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  ]),
  shop: PropTypes.bool,
  canonical: PropTypes.string,
  title: PropTypes.string,
  setMobileMenu: PropTypes.func,
  mobileMenu: PropTypes.bool,
}

Header.defaultProps = {
  breadcrumbs: false,
  shop: false,
  canonical: null,
  title: null,
}

export default Header
