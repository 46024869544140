import * as React from "react"
import { useState, useCallback } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import FileUpload from "../components/form/FileUpload"
import { IconArrowRight, IconMail2, IconPhone, IconPlace } from "../components/icons/Icons"
import Testimonials from "../components/common/Testimonials"
import BrandsWeBuy from "../components/common/BrandsWeBuy"
import Map from "../components/common/Map"
import FormWrapper from "../components/form/FormWrapper"
import useFormState from "../components/form/hooks/useFormState"
import HowItWorks from "../components/common/HowItWorks"

const ShopPage = () => {
  const [form, setForm, updateForm] = useFormState({
    name: "",
    phone: "",
    email: "",
    description: "",
    photos: [],
  })
  const updateUploadedFiles = useCallback(
    files =>
      setForm(f => {
        return { ...f, photos: files }
      }),
    [setForm]
  )
  return (
    <Layout className={"mx-auto"} breadcrumbs={false} shop={true}>
      <Seo
        title="Past Purchases | Buyers of Rolex, Cartier, Patek Philippe..."
        description="Checkout the latest products we have purchased by the best online luxury goods buyers in the US."
        canonical={"/shop/"}
      />
      <div className={"grid w-full"}>
        <div style={{ gridArea: "1/1" }} className={"xl:min-h-screen"}>
          <div className={"relative z-10 "}>
            <div className={"container mx-auto pt-4 pb-10 text-5xl text-white lg:pt-10"}>
              The solution <br className={"hidden lg:block"} />
              to selling your jewelry.
            </div>
            <div className={"container mx-auto flex justify-start py-8 text-sm"}>
              <FormWrapper
                name={"Sell Us Form"}
                action={"/thank-you/"}
                form={form}
                category={"watch"}
                className={"w-full bg-white p-8 lg:w-1/2 xl:w-1/4"}
                method={"post"}
              >
                <div>
                  When you’re ready to sell your watch, jewelry or diamonds sellusyourjewelry.com
                  specialist is here to help.
                </div>
                <div className={"my-4"}>
                  Fill out the form below to receive <strong>a FREE QUOTE!</strong>
                </div>
                <label htmlFor={"name"} className={"text-bold"}>
                  Full name
                </label>
                <input
                  type={"text"}
                  name="name"
                  id={"name"}
                  className={"my-2 block w-full border border-gray-700 p-2"}
                  onChange={updateForm}
                />
                <label htmlFor={"email"} className={"text-bold"}>
                  Email
                </label>
                <input
                  type={"text"}
                  name={"email"}
                  id={"email"}
                  className={"my-2 block w-full border border-gray-700 p-2"}
                  onChange={updateForm}
                />
                <label htmlFor={"phone-number"} className={"text-bold"}>
                  Phone number
                </label>
                <input
                  type={"text"}
                  name={"phone"}
                  id={"phone"}
                  className={"my-2 block w-full border border-gray-700 p-2"}
                  onChange={updateForm}
                />
                <label htmlFor={"description"} className={"text-bold"}>
                  Description
                </label>
                <textarea
                  id={"description"}
                  name={"description"}
                  className={"my-2 block h-24 w-full border border-gray-700 p-2"}
                  onChange={updateForm}
                />
                <FileUpload
                  id={"photos"}
                  label={"Add photos"}
                  name={"photos"}
                  accept={".jpg,.png,.jpeg,.webp,.gif,.pdf,.xslx"}
                  updateFilesCb={updateUploadedFiles}
                  multiple
                />
                Please attach photos of items & appraisals/certificates.
                <button className={"btn-cta p-4 mx-auto block my-2"}>Get quote</button>
              </FormWrapper>
            </div>
          </div>
        </div>
        <StaticImage
          src={"../images/hero-image.png"}
          alt={"The solution to selling your jewelry"}
          style={{ gridArea: "1/1" }}
          backgroundColor={"#CEAA72"}
          objectFit={"cover"}
          placeholder={"none"}
          loading={"eager"}
          className={"hero relative col-start-1 row-start-1 h-full overflow-hidden object-cover"}
        />
      </div>

      <div className={"container mx-auto mt-16 flex flex-wrap gap-y-16 px-4 md:p-0"}>
        <section className={"w-full"}>
          <div>About Us</div>
          <h1 className={"text-3xl xl:w-1/3"}>
            SellUsYourJewelry.com is Gray & Sons’ dedicated online purchasing site for preowned
            jewelry.
          </h1>
        </section>

        <section className={"flex w-full flex-wrap"}>
          <StaticImage
            src={"../images/image1.png"}
            alt={"Over 30 Years of Experience"}
            outputPixelDensities={[0.25, 0.5, 1, 2, 3]}
            breakpoints={[360, 375, 414, 640, 720, 750, 768, 828, 960, 1024, 1152]}
            sizes={
              "(min-width: 640px) 100vw, (min-width: 768px) 100vw, (min-width: 1024) 48em,  (min-width: 1280px) 60em, (min-width: 1536px) 72em, 100vw"
            }
            className={"w-full lg:w-3/4"}
          />
          <div className={"w-full lg:w-1/4 lg:pl-16 2xl:pl-32"}>
            <h2 className={"my-4 text-2xl"}>Over 30 Years Experience</h2>
            <p className={"mt-4 mb-16 text-sm"}>
              Gray & Sons first went into business in 1980. While a lot has changed during this
              time, there are fundamental principles that have governed Gray & Sons since day one.
              We strive to offer high-quality merchandise and service, at an affordable cost.
              Maintaining this goal and holding ourselves to a higher standard than others has led
              us to grow into the business that we are today.
            </p>
            <h2 className={"my-4 text-2xl"}>Personalized Service</h2>
            <p className={"mt-4 mb-16 text-sm"}>
              Gray & Sons first went into business in 1980. While a lot has changed during this
              time, there are fundamental principles that have governed Gray & Sons since day one.
              We strive to offer high-quality merchandise and service, at an affordable cost.
              Maintaining this goal and holding ourselves to a higher standard than others has led
              us to grow into the business that we are today.
            </p>
            <h2 className={"my-4 text-2xl"}>Best Value</h2>
            <p className={"mt-4 mb-16 text-sm"}>
              Gray & Sons first went into business in 1980. While a lot has changed during this
              time, there are fundamental principles that have governed Gray & Sons since day one.
              We strive to offer high-quality merchandise and service, at an affordable cost.
              Maintaining this goal and holding ourselves to a higher standard than others has led
              us to grow into the business that we are today.
            </p>
            <Link to={"/about-us/"} className={"btn-cta p-4 mx-auto block my-2"}>
              Learn More
            </Link>
          </div>
        </section>

        <HowItWorks />
        <section className={"w-full"}>
          <div className={"how-it-works flex flex-wrap items-center justify-center gap-4 px-4"}>
            <div className={"flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1"}>
              <StaticImage src={"../images/homepage/value.png"} alt={"Valuation"} />
              <h3>Get a Valuation</h3>
              <p>
                Tell us about your jewelry with our simple form. The more information & photos you
                share with us the more accurate the market valuation will be.
              </p>
            </div>
            <div className={"hidden flex-shrink self-center md:block"}>
              <IconArrowRight className={"text-accent"} />
            </div>
            <div className={"flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1"}>
              <StaticImage src={"../images/homepage/shipping.png"} alt={"Shipping"} />
              <h3>Send Us Your Jewelry</h3>
              <p>
                If you like the quote you’ll receive a pre-paid shipping label including insurance.
                Face-to-face collection can also be arranged.
              </p>
            </div>
            <div className={"hidden flex-shrink self-center lg:block"}>
              <IconArrowRight className={"text-accent"} />
            </div>
            <div className={"flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1"}>
              <StaticImage src={"../images/homepage/inspection.png"} alt={"Inspection"} />
              <h3>Full Jewelry Inspection</h3>
              <p>
                Once we receive your jewelry our qualified experts will inspect its condition and
                authenticity.
              </p>
            </div>
            <div className={"hidden flex-shrink self-center md:block"}>
              <IconArrowRight className={"text-accent"} />
            </div>
            <div className={"flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1"}>
              <StaticImage src={"../images/homepage/payment.png"} alt={"Payment"} />
              <h3>Receive Payment</h3>
              <p>Once finalized you will quickly receive payment via check or wire payment.</p>
            </div>
          </div>
        </section>

        <section className={"w-full"}>
          <Link
            to={"/what-are-you-looking-to-sell/"}
            className={"btn-cta max-w-auto mx-auto block w-80"}
          >
            Get Quote
          </Link>
        </section>

        <section className={"w-full"}>
          <h2 className={"text-center text-4xl uppercase"}>Testimonials</h2>
        </section>
        <Testimonials />

        <section className={"flex w-full flex-wrap lg:grid lg:grid-cols-4"}>
          <div className={"order-7 lg:col-span-2 lg:col-start-1 lg:row-start-1"}>
            <StaticImage
              className={"h-full w-full"}
              src={"../images/homepage/homepage-1.png"}
              alt={"Jewelry we Buy"}
            />
          </div>
          <div className={"order-1 w-1/2 lg:col-start-3 lg:row-start-1 lg:w-auto"}>
            <StaticImage
              className={"h-full w-full"}
              src={"../images/homepage/homepage-2.png"}
              alt={"Jewelry we Buy"}
            />
          </div>
          <div className={"order-4 w-1/2 lg:col-start-4 lg:row-span-2 lg:row-start-1 lg:w-auto"}>
            <StaticImage
              className={"h-full w-full"}
              src={"../images/homepage/homepage-3.png"}
              alt={"Jewelry we Buy"}
            />
          </div>
          <div className={"order-5 w-1/2 lg:col-start-1 lg:row-span-2 lg:row-start-2 lg:w-auto"}>
            <StaticImage
              className={"h-full w-full"}
              src={"../images/homepage/homepage-4.png"}
              alt={"Jewelry we Buy"}
            />
          </div>
          <div className={"order-2 w-1/2 lg:col-start-2 lg:row-start-3 lg:w-auto"}>
            <StaticImage
              className={"h-full w-full"}
              src={"../images/homepage/homepage-5.png"}
              alt={"Jewelry we Buy"}
            />
          </div>
          <div className={"order-0 lg:col-span-2 lg:col-start-3 lg:row-start-3"}>
            <StaticImage
              className={"h-full w-full"}
              src={"../images/homepage/homepage-6.png"}
              alt={"Jewelry we Buy"}
            />
          </div>
          <div
            className={
              "order-3 self-center py-8 text-center text-xl leading-relaxed md:text-3xl lg:col-span-2 lg:col-start-2 lg:row-start-2 lg:p-16 lg:text-4xl"
            }
          >
            SellUsYourJewelry.com is Gray & Sons’ dedicated online purchasing site for preowned
            jewelry.
          </div>
        </section>

        <section className={"w-full"}>
          <h2 className={"text-center text-4xl uppercase"}>Brands we Buy</h2>
        </section>
        <BrandsWeBuy />

        <section className={"w-full"}>
          <h2 className={"text-center text-4xl uppercase"}>Still have Questions?</h2>
        </section>
        <section className={"w-full"}>
          <p className={"mx-auto w-full text-center text-lg lg:w-1/2 lg:text-xl 2xl:w-2/5"}>
            Our highly trained specialists, coupled with our international sales reach, allow us to
            offer the very best prices for your jewelry.
          </p>
        </section>

        <section className={"flex w-full flex-wrap gap-16 lg:flex-nowrap"}>
          <div
            className={
              "align-center flex h-full w-full flex-col justify-center py-24 text-center lg:w-1/3"
            }
          >
            <div className={"mb-8 text-5xl text-accent"}>
              <IconPlace />
            </div>
            <div className={"text-3xl uppercase"}>Visit Us</div>
            <div className={"mt-16 text-link"}>9595 Harding Avenue, Bal Harbour Florida 33154</div>
          </div>
          <div
            className={
              "align-center flex w-full flex-col justify-center border-l border-r border-accent text-center lg:w-1/3"
            }
          >
            <div className={"mb-8 text-5xl text-accent"}>
              <IconPhone />
            </div>
            <div className={"text-3xl uppercase"}>Call Us</div>
            <div className={"mt-16 text-accent"}>
              <a href={"tel:+13057706955"}>tel: 305 770 6955</a>
            </div>
          </div>
          <div className={"align-center flex w-full flex-col justify-center text-center lg:w-1/3"}>
            <div className={"mb-8 text-5xl text-accent"}>
              <IconMail2 />
            </div>
            <div className={"text-3xl uppercase"}>Email Us</div>
            <div className={"mt-16 text-accent"}>
              <a href={"mailto:sell@grayandsons.com"}>sell@grayandsons.com</a>
            </div>
          </div>
        </section>
      </div>

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
}

export default ShopPage
