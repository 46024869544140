import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { IconQuote, IconStar } from "../icons/Icons"
import Carousel from "../Carousel"

const Testimonials = ({slides = {"xs": 1, "md": 2, "xl": 3}}) =>
{
    const { reviews } = useStaticQuery(
        graphql`
            query {
                reviews: allStrapiGoogleReview(
                    filter: {
                        rating: {gt: 4}
                        body: {regex: "/.{10,}/"}
                    }
                    limit: 9
                    sort: {order: DESC, fields: date}
                ) {
                    nodes {
                        date(fromNow: true)
                        link
                        review_count
                        full_name
                        body
                        rating
                        id
                        image {
                            url
                        } 
                    }
                }
            }
        `
    )

    let prepareImage = (image) => {
        image.url = image.url.replace("/o/", "/r/")
        return getImage({
            "gatsbyImageData": {
                "layout": "constrained",
                "backgroundColor": "#FFFFFF",
                "images": {
                    "fallback": {
                        "src": image.url.replace(".jpg", "-w40.jpg"),
                        "srcSet": `${image.url.replace(".jpg", "-w60.jpg")} 1x, ${image.url.replace(".jpg", "-w120.jpg")} 2x, ${image.url.replace(".jpg", "-w180.jpg")} 3x`
                    },
                    "sources": [
                        {
                            "srcSet": `${image.url.replace(".jpg", "-w60.avif")} 1x, ${image.url.replace(".jpg", "-w120.avif")} 2x, ${image.url.replace(".jpg", "-w180.avif")} 3x`,
                            "type": "image/avif",
                        },
                        {
                            "srcSet": `${image.url.replace(".jpg", "-w60.webp")} 1x, ${image.url.replace(".jpg", "-w120.webp")} 2x, ${image.url.replace(".jpg", "-w180.webp")} 3x`,
                            "type": "image/webp",
                        }
                    ]
                },
                "width": 60,
                "height": 60
            }
        })
    }

    const map_class = (i) => {
        switch (i % 3) {
            case 0: return "-translate-x-6 rotate-6 "
            case 1: return "-translate-x-3 rotate-3"
            default: return "-translate-x-1 -rotate-6"
        }
    }
    return (<Carousel
        slides={slides}
        infiniteLoop={false} className={"py-8 mx-12"}
        gap={16}
        scrollStep={3} >
        {reviews.nodes.map((k, i) => {
            const bg_transform = map_class(i)
            return (
                <div key={"testimonials-" + k.id} className={"relative xl:h-96 2xl:h-100 transition ease-out duration-700 "}>
                    <div
                        className={"transform " + bg_transform + " mx-4 bg-accent h-full w-full absolute"} />
                    <div
                        className={"bg-white p-4 relative justify-center testimonial-shadow h-full flex flex-col"}>
                        <div className={"text-4xl mt-8 mb-4 text-center"}>{k.full_name}</div>
                        <div className={"block mx-auto"}>
                            <IconStar className={"text-accent"} />
                            {/*                          className={"text-accent"}/>
                            {/*{[1, 2, 3, 4, 5].map((r) => {*/}
                            {/*    if (k.rating >= r)*/}
                            {/*        return (<IconStar key={'testimonials-' + k.id + '-star-' + r}*/}
                            {/*                          className={"text-accent"}/>)*/}
                            {/*    return (*/}
                            {/*        <IconStarOutline key={'testimonials-' + k.id + '-star-' + r}*/}
                            {/*                         className={"text-accent"}/>)*/}
                            {/*})}*/}
                        </div>
                        <div className={"quote-icon text-center text-5xl"}><IconQuote /></div>
                        <div
                            className={"text-sm px-8 mb-4 text-center leading-6 line-clamp-6 md:line-clamp-3 xl:line-clamp-6 2xl:line-clamp-8"}>{k.body}</div>
                        <div className={"flex-grow"} />
                    </div>
                    <div className={"absolute w-full -top-5"}>
                        <div className={"mx-auto w-15 rounded-full overflow-hidden"}>
                            <GatsbyImage
                                alt={k.full_name}
                                image={prepareImage(k.image)} />
                        </div>
                    </div>
                </div>
            )
        })}
    </Carousel>)
}

export default Testimonials
