import * as React from "react"
import { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Carousel from "../Carousel"
import { slugify } from "../../helpers/StringHelpers"

const BrandsWeBuy = () => {
    const [current, setCurrent] = useState(-2)
    const [slides, setSlides] = useState(1)
    const { brands } = useStaticQuery(
        graphql`
            query {
                brands: allBrandsWeBuyJson{
                    nodes {
                        name
                        url
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: CONSTRAINED
                                    height: 200
                                    aspectRatio: 1.5
                                    transformOptions: {fit:CONTAIN}
                                    backgroundColor: "#FFFFFF"
                                )
                            }
                        }
                    }
                }
            }
        `
    )
    return (<Carousel slides={{ "xs": 1, "md": 2, "xl": 3 }}
        infiniteLoop={true} className={"py-8 mx-12"}
        gap={16}
        onCurrentChange={setCurrent}
        onSlidesChange={setSlides}
        scrollStep={1} >
        {brands.nodes.map((k, i) => {
            const active = (slides !== 3 || i === ((brands.nodes.length + Math.floor(slides / 2) + current) % brands.nodes.length)) ? "transform scale-100 " : "transform scale-75 "
            return (
                <div key={"brands-" + slugify(k.name)} className={"testimonial-shadow responsive-box relative transition ease-out duration-700 " + active}>
                    <Link to={k.url} className={"absolute z-10 w-full h-full p-12 flex flex-col cursor-pointer"}>
                        <div className={"max-w-full max-h-60 object-fill text-center self-center"}>
                            <GatsbyImage className={"mx-auto"} alt={k.name} image={getImage(k.image)} />
                        </div>
                        <div className={"uppercase flex-grow uppercase text-3xl"}>{k.name}</div>
                        <span to={k.url} className={"btn-cta w-80 max-w-full"}>Learn More</span>
                    </Link>
                </div>
            )
        })}
    </Carousel>)
}

export default BrandsWeBuy
